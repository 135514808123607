import { getPageContent, PageContentResponse } from 'application/repositories/pageContentRepository';
import { LayoutFeature } from 'features/Layouts/Layout/LayoutFeature';
import { PageTypeMapFeature } from 'features/Mappers/PageType/PageTypeFeature';
import { getAccessToken } from 'helpers/auth';
import { ApiRedirect } from 'helpers/error';
import logger from 'helpers/logger';
import { GetServerSideProps, NextPage } from 'next';
import { getServerSession, Session } from 'next-auth';
import { getSession } from 'next-auth/react';
import React from 'react';
import { authOptions } from '../../api/auth/[...nextauth]';

const Page: NextPage<PageContentResponse> = (pageContent) => {
	const { page } = pageContent ?? {};

	return (
		<LayoutFeature {...pageContent}>
			<PageTypeMapFeature {...page} />
		</LayoutFeature>
	);
};

type PageProps = {
	host: string | string[];
	content: Umbraco.PageContent;
	session: Session | null;
};
export const getServerSideProps: GetServerSideProps<PageProps> = async (context) => {
	const { params, preview, previewData: untypedPreviewData, req } = context;
	const { host } = params;
	try {
		const url = params.page ? (params.page as string[]).join('/') : '';

		const accessToken = await getAccessToken(req);
		const content = await getPageContent({
			url,
			host,
			preview,
			previewData: untypedPreviewData as Models.PreviewData,
			accessToken: accessToken,
		});

		const pageContent = content as PageContentResponse;

		const serverSession = await getServerSession(context, authOptions);

		return {
			props: {
				host,
				content: pageContent,
				session: serverSession ? JSON.parse(JSON.stringify(serverSession, (key, value) => (value === undefined ? null : value))) : null,
			},
		};
	} catch (err) {
		if (err instanceof ApiRedirect) {
			throw err;
		} else if (err?.statusCode === 404) {
			return {
				notFound: true,
			};
		} else if (err?.statusCode === 401 || err?.statusCode === 403) {
			logger.info(`Unauthorized access`);
			//Not logged in or unauthorized. Render the login/no access content from the CMS
			const pageContent = err.content as PageContentResponse;
			const session = await getSession(context);
			logger.info('host:' + host);
			logger.info('content:' + pageContent);
			logger.info('session:' + session);

			return {
				props: {
					host,
					content: pageContent,
					session: session,
				},
			};
		}
		logger.error(err);
		throw new Error(err);
	}
};

export default Page;
